<template>
  <div class="page d-flex justify-content-center">
    <van-loading
      v-if="state.loading"
      class="justify-content-center"
      color="#1989fa"
      size="36"
      vertical
    >
      加载中
    </van-loading>
    <scan-pile-code
      v-else-if="!state.pile_code"
      :pullscan="state.pullscan"
      @get-scan-result="parseCode"
    />
    <power-recharge-form
      v-else
      :pile_code="state.pile_code"
      :gun_number="state.gun_number"
      @reset-page="resetPage"
    />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast } from "vant";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { onMounted, onUnmounted, reactive } from "vue";
import { signaturePack } from "@/api/wechat.service";
import ScanPileCode from "@/components/power/ScanPileCode.vue";
import PowerRechargeForm from "@/components/power/PowerRechargeForm.vue";
import { heartbeat } from "@/api/account.service";
export default {
  components: { ScanPileCode, PowerRechargeForm },
  setup() {
    let timer = null;
    let currentUser = null;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      pile_code: "",
      gun_number: 0,
      pullscan: true,
      loading: true,
    });

    const parseCode = (scanresult) => {
      if (scanresult && scanresult.length == 14) {
        state.pile_code = scanresult.substring(0, 13);
        state.gun_number = parseInt(scanresult.charAt(13));
        window.sessionStorage.setItem("power.recharge.code", scanresult);
        return true;
      }
      window.sessionStorage.removeItem("power.recharge.code");
      return false;
    };

    const signaturePage = async () => {
      try {
        const { result } = await signaturePack(location.href.split("#")[0]);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: [
            "hideOptionMenu",
            "getLocation",
            "scanQRCode",
            "checkJsApi",
          ],
          openTagList: ["wx-open-subscribe"], // 需获取此标签, 在子组件中可能用到, 详见: PowerRechargeForm
        });
        wx.ready(() => {
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          Toast(res.errMsg);
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({
          message: msg ? msg : "前端脚本异常/页面签名失败",
          position: "bottom",
        });
        if (!msg) console.log(error);
      }
    };

    // 重新显示扫码组件
    const resetPage = () => {
      state.loading = false;
      state.pullscan = false;
      state.pile_code = "";
      state.gun_number = 0;
    };

    const redirectToLogin = () => {
      router.push({
        name: "Login",
        query: { redirect: encodeURIComponent("/power/recharge") },
      });
    };

    const checkHeartbeat = async () => {
      try {
        await heartbeat();
      } catch (error) {
        redirectToLogin();
      }
    };

    onMounted(() => {
      // code 参数以 url 参数优先级较高, 然后才是本地 session 储存中的数据
      let { code } = route.query;
      if (!code) {
        code = window.sessionStorage.getItem("power.recharge.code");
      }
      parseCode(code); // 会储存 session , 位置不能后移

      // 未登录跳转去登录, 如果 code 有效已记录到 session, 返回时直接取用
      currentUser = store.getters.currentUser;
      if (!currentUser) {
        redirectToLogin();
        return;
      }

      checkHeartbeat();

      // 定时检测登录情况, 避免后端重启使用户登录失效而前端不知
      // 如果需要优化性能, 这部分功能可以直接删除
      timer = setInterval(() => {
        checkHeartbeat();
      }, 30000);

      // JSSDK页面签名
      signaturePage();
      setTimeout(() => {
        state.loading = false;
      }, 300);
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return { state, parseCode, resetPage };
  },
};
</script>
