<template>
  <div>
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else class="page with-nav-bar">
      <van-nav-bar
        title="开始充电"
        left-text="首页"
        right-text="充值"
        :left-arrow="true"
        :fixed="true"
        @click-left="$router.push({ name: 'Home' })"
        @click-right="$router.push({ name: 'UserRecharge' })"
      />
      <van-cell-group title="资费详情">
        <van-cell
          v-if="state.fee.rate_type == 1"
          title="电费"
          value="浮动费率"
          is-link
          @click="showFloatFee"
        />
        <van-cell
          v-else
          title="电费"
          :value="`${state.fee.fixed_rate / 100}元/kW·h`"
        />
        <van-cell
          title="服务费"
          :value="`${state.fee.final_service_rate / 100}元/kW·h`"
        />
      </van-cell-group>
      <van-cell-group title="设备信息">
        <van-cell
          title="设备端口"
          :value="`${state.device.name} / ${gunName} / ${state.device.power}kW`"
        />
        <van-cell title="设备状态" is-link @click="prepareRecharge(true)">
          <template #value>
            <span class="text-danger" v-if="state.device.offline">离线</span>
            <span class="text-success" v-else>在线</span>
          </template>
        </van-cell>
        <van-cell title="连枪状态" is-link @click="prepareRecharge(true)">
          <template #value>
            <span v-if="state.gun.connection_status == 0" class="text-danger"
              >未连接</span
            >
            <span
              v-else-if="
                state.gun.connection_status == 1 || // 交流桩半连接, 未充电
                state.gun.connection_status == 2 // 直流桩已连接, 是否充电未知, 需另外判断
              "
              class="text-success"
            >
              {{ state.gun.work_status == 2 ? "充电中" : "已连接" }}
            </span>
            <span v-else class="text-warning">未知</span>
          </template>
        </van-cell>
      </van-cell-group>
      <van-notice-bar
        left-icon="info-o"
        text="提示：启动过程中请勿拔出充电枪，最长等待90秒！"
      />
      <div class="py-3 action-button-area text-center">
        <van-button
          v-if="state.gun.work_status != 2"
          type="success"
          @click="startRecharge"
          :disabled="!canSubmit"
        >
          {{ buttonText }}
        </van-button>
        <van-button
          v-else
          type="primary"
          @click="
            $router.push({
              name: 'UserChargingOrderDetail',
              query: { order_id: state.gun.order_id },
            })
          "
          >查看订单</van-button
        >
        <p
          :class="
            state.balance + state.giftBalance > state.minBalance
              ? 'text-secondary mb-2'
              : 'text-danger mb-2'
          "
        >
          {{ `账户余额: ${state.balance + state.giftBalance}元` }}
        </p>
        <a
          v-if="showSubscribeLink && !popSubscribe"
          class="text-primary link"
          @click="popSubscribe = true"
          >订阅服务消息</a
        >
      </div>
      <van-popup
        v-model:show="popFloatFee"
        position="bottom"
        :style="{ height: '58%', padding: '10px 20px' }"
        :safe-area-inset-bottom="true"
        round
      >
        <van-divider>浮动费率</van-divider>
        <van-cell v-for="(item, index) in floatingSettings.items" :key="index">
          <template #title>
            {{ `${item.begin}~${item.end}` }}
          </template>
          <template #value> {{ item.power }}元/kW·h </template>
        </van-cell>
      </van-popup>
      <van-popup
        v-if="ONETIME_MSG_IDS.length > 0"
        v-model:show="popSubscribe"
        position="bottom"
        :style="{ height: '200px', padding: '10px 20px', textAlign: 'center' }"
        :safe-area-inset-bottom="true"
        :lazy-render="false"
        round
      >
        <van-divider>订阅消息</van-divider>
        <p>为了及时获取充电状态, 请订阅服务消息</p>
        <div v-html="wxOpenTagHtml"></div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  Divider,
  Popup,
  NoticeBar,
  Toast,
  Cell,
  CellGroup,
} from "vant";
import { checkSubscribe, subscribeOnetimeMessage } from "@/api/message.service";
import { getRechargeParams, startPowerRecharge } from "@/api/device.service";
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { LOGOUT } from "@/store/modules/auth";
import {
  FORCE_SUBSCRIBE,
  WECHAT_SUBSCRIBE_URL,
  ONETIME_MSG_IDS,
} from "@/config/config.local";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "PowerRechargeForm",
  components: {
    [Divider.name]: Divider,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  props: {
    pile_code: {
      type: String,
      required: true,
    },
    gun_number: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    let timer = null;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      device: null, // 设备信息
      gun: null, // 枪口信息
      fee: null, // 资费信息
      balance: 0.0, // 当前用户余额
      giftBalance: 0.0, // 赠金余额
      minBalance: 0.01, // 最小可开启充电的余额
      loading: true,
    });

    const gunName = computed(() => {
      const names = ["主枪", "A枪", "B枪"];
      if (state.gun.gun_number < 0 || state.gun.gun_number > 2) {
        return "未知";
      }
      return names[state.gun.gun_number];
    });

    const gunCanWork = computed(() => {
      if (
        state.gun.work_status == 0 || // 空闲中
        state.gun.work_status == 1 || // 准备就绪
        state.gun.work_status == 3 || // 已结束
        state.gun.work_status == 4 // 启动失败
      ) {
        return true;
      }
      return false;
    });

    const gunCanStart = computed(() => {
      if (
        Math.ceil(new Date().getTime() / 1000) - state.gun.last_started_at <
        60
      ) {
        return false;
      }
      return true;
    });

    const buttonText = computed(() => {
      if (state.gun.work_status == 2) {
        return "正在充电";
      }
      if (gunCanStart.value) {
        return state.balance + state.giftBalance > state.minBalance
          ? "立即启动"
          : "余额不足";
      }
      return "启动中...";
    });

    const canSubmit = computed(() => {
      if (
        state.device.offline ||
        state.gun.connection_status == 0 ||
        state.balance + state.giftBalance < state.minBalance ||
        !gunCanWork.value ||
        !gunCanStart.value
      ) {
        return false;
      }

      return true;
    });

    // 获取 电站/设备/费用信息
    const prepareRecharge = async (showtoast) => {
      if (showtoast) {
        Toast.loading({
          message: "加载中",
          forbidClick: true,
          duration: 0,
        });
      }
      try {
        const { result } = await getRechargeParams(
          props.pile_code,
          props.gun_number
        );

        state.balance = result.balance;
        state.giftBalance = result.giftBalance;
        state.device = result.device;
        state.gun = result.gun;
        state.fee = result.fee;
        state.loading = false;
        if (showtoast) {
          Toast.clear();
        }

        // 未关注用户强制关注才能充电, 跳转前需要强制登出, 在用户关注后返回系统才会要求登录, 以获取最新的关注信息
        if (!result.user.is_subscribed && FORCE_SUBSCRIBE) {
          clearInterval(timer);
          Dialog.alert({
            title: "温馨提示",
            message: "请先关注公众号，方便您以后查看订单！",
            theme: "round-button",
            confirmButtonText: "前往关注",
          }).then(() => {
            store.dispatch(LOGOUT).then(() => {
              window.location.href = WECHAT_SUBSCRIBE_URL;
            });
          });
          return;
        }

        // 未连枪提示连接
        if (result.gun.connection_status == 0 && !popSubscribe.value) {
          Dialog.alert({
            title: "温馨提示",
            message: "充电枪未连接",
            theme: "round-button",
          }).then(() => {});
        } else {
          Dialog.close();
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        Toast.fail({ message: msg ? msg : "前端脚本异常", duration: 3500 });
        window.sessionStorage.removeItem("power.recharge.code");
        setTimeout(() => {
          context.emit("reset-page"); // 通知上级组件重新渲染页面
        }, 3900);
      }
    };

    // 注意: 下发开启命令成功后直接跳转, 并不关闭 Toast, 在跳转后的页面关闭 Toast
    const startRecharge = async () => {
      Toast.loading({
        message: "启动中",
        forbidClick: true,
        duration: 0,
      });
      try {
        const { result } = await startPowerRecharge({
          pile_code: state.device.pile_code,
          gun_number: state.gun.gun_number,
          time: 0, // 目前只支持自动充满, 时间传入0
        });
        setTimeout(() => {
          router.push({
            name: "UserChargingOrderDetail",
            query: { order_id: result.order_id, from: "form" },
          });
        }, 1000);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const popFloatFee = ref(false);
    const floatingSettings = reactive({ items: [] });
    const showFloatFee = () => {
      popFloatFee.value = true;
      const items = JSON.parse(state.fee.floating_settings);
      floatingSettings.items.length = 0;
      floatingSettings.items.push(...items);
    };

    // 微信开放标签相关
    // 延迟加载微信开放标签, 不然Vue会报警告, 无法识别组件
    // @link https://developers.weixin.qq.com/community/develop/doc/0000c449cec10069fa9af5ee556000
    const wxOpenTagHtml = ref("");
    const popSubscribe = ref(false);
    const showSubscribeLink = ref(false);
    const checkOnetimeMessage = async () => {
      if (ONETIME_MSG_IDS.length == 0) {
        return;
      }
      const template_ids = ONETIME_MSG_IDS.join(",");
      try {
        const { result } = await checkSubscribe(template_ids);
        if (result.needSubscribe) {
          Dialog.close();
          popSubscribe.value = true;
          showSubscribeLink.value = true;
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        Toast.fail({ message: msg ? msg : "前端脚本异常", duration: 3500 });
      }
    };

    onMounted(() => {
      prepareRecharge(true);
      timer = setInterval(() => {
        prepareRecharge(false);
      }, 5000);

      // 延迟注入微信开放标签HTML, 注意 popup 组件需要关闭 lazy-render, 否则绑定事件会失败
      if (ONETIME_MSG_IDS.length > 0) {
        setTimeout(() => {
          const tids = ONETIME_MSG_IDS.join(",");
          wxOpenTagHtml.value = `<wx-open-subscribe id="subscribe-btn" template="${tids}"></wx-open-subscribe>`;
          checkOnetimeMessage();
        }, 300);

        // 延迟绑定事件, 待HTML先注入成功
        setTimeout(() => {
          var btn = document.getElementById("subscribe-btn");
          btn.addEventListener("success", async function (e) {
            const data = [];
            const details = JSON.parse(e.detail.subscribeDetails);
            for (const template_id in details) {
              if (Object.hasOwnProperty.call(details, template_id)) {
                const rs = JSON.parse(details[template_id]);
                data.push({ template_id, ...rs });
              }
            }
            try {
              await subscribeOnetimeMessage({
                data: JSON.stringify(data),
              });
              popSubscribe.value = false;
              showSubscribeLink.value = false;
            } catch (error) {
              const { data, statusText } = error;
              const msg = data && data.msg ? data.msg : statusText;
              if (!msg) console.log(error);
              Toast.fail({
                message: msg ? msg : "前端脚本异常",
                duration: 3500,
              });
            }
          });
        }, 600);
      }
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return {
      ONETIME_MSG_IDS,
      state,
      gunName,
      buttonText,
      canSubmit,
      prepareRecharge,
      startRecharge,
      popFloatFee,
      floatingSettings,
      showFloatFee,
      wxOpenTagHtml,
      popSubscribe,
      showSubscribeLink,
    };
  },
};
</script>
