import ApiService from "./api.service";

export function heartbeat() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/heartbeat")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getBalance() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getBalance")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getRechargeLog(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getRechargeLog", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getRechargeDetail(trade_no) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getRechargeDetail", { trade_no })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function applyRechargeRefund(trade_no) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/applyRechargeRefund", { trade_no })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function updateAgentInfo(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/updateAgentInfo", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getChargingOrderLog(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getChargingOrderLog", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function applyWithdraw(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/applyWithdraw", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getChargingOrderDetail(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getChargingOrderDetail", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function settleChargingOrder(order_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/settleChargingOrder", { order_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function unbindPhone() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/unbindPhone")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getWalletLog(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getWalletLog", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getBalanceCash(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getBalanceCash", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getBalanceCashDetail(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getBalanceCashDetail", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getBalanceGift(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getBalanceGift", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getBalanceGiftDetail(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/user/getBalanceGiftDetail", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 将扫码登录的结果推送到服务端
export function pushScanResult(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/auth/pushScanResult", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
