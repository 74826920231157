import ApiService from "./api.service";

export function subscribeOnetimeMessage(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/message/subscribe", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function checkSubscribe(template_ids) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/message/checkSubscribe", { template_ids })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
