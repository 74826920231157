import { FRONTEND_DOMAIN_WHITELIST as whitelist } from "@/config/config.local";
import Moment from "moment";
Moment.locale("zh-CN");

export const time2date = (time) => {
  return Moment(time).format("YYYY-MM-DD");
};

export const time2datetime = (time) => {
  return Moment(time).format("YYYY-MM-DD HH:mm:ss");
};

export function parseUrl(url) {
  const obj = {};
  const reg = /[?&][^?&]+=[^?&]+/g;
  const arr = url.split("#")[0].match(reg);
  if (arr) {
    arr.forEach((item) => {
      const tempArr = item.substring(1).split("=");
      const key = decodeURIComponent(tempArr[0]);
      const val = decodeURIComponent(tempArr[1]);
      obj[key] = val;
    });
  }
  return obj;
}

export function getValidateScanResult(res) {
  const { errMsg, resultStr } = res;
  if (errMsg != "scanQRCode:ok" || !resultStr) {
    return false;
  }
  if (resultStr.indexOf("http://") != 0 && resultStr.indexOf("https://") != 0) {
    return false;
  }
  let domainValidate = false;
  for (const index in whitelist) {
    if (resultStr.indexOf(whitelist[index]) != -1) {
      domainValidate = true;
      break;
    }
  }
  if (!domainValidate) {
    return false;
  }
  const { code } = parseUrl(resultStr);
  return code ? code : false;
}

export function isWechat() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}
