<template>
  <div class="page bg-linear">
    <div class="c1">
      <div class="c2">
        <div class="c3" @click="scan">
          <van-icon name="scan" size="96" />
          <div>点击扫码充电</div>
        </div>
      </div>
    </div>
    <div class="text-center text-secondary text-sm">
      使用充电即表示您同意本平台用户协议
    </div>
    <div class="py-4 action-button-area text-center">
      <van-button type="primary" :to="{ name: 'Home' }">个人中心</van-button>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast } from "vant";
import { getValidateScanResult } from "@/utils/util";
import { onMounted } from "vue";
export default {
  name: "ScanPileCode",
  props: {
    // 是否直接拉起摄像头扫码
    pullscan: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const scan = async () => {
      await wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode"],
        success: function (res) {
          const code = getValidateScanResult(res);
          if (code && code.length == 14) {
            context.emit("get-scan-result", code);
          } else {
            console.log(`res.errMsg=${res.errMsg}`);
            console.log(`res.resultStr=${res.resultStr}`);
            return Toast.fail({ message: "二维码无效", icon: "scan" });
          }
        },
      });
    };

    onMounted(() => {
      if (props.pullscan) {
        scan();
      }
    });

    return { scan };
  },
};
</script>

<style lang="css" scoped>
.bg-linear {
  background: linear-gradient(180deg, #007bff, #fff 50%, #fff);
}
.c1,
.c2,
.c3 {
  border-radius: 50%;
  text-align: center;
  padding-top: 20px;
  margin: 0 auto;
}
.c1 {
  width: 260px;
  height: 260px;
  margin: 60px auto 30px auto;
  background-color: rgba(97, 170, 248, 0.5);
}
.c2 {
  width: 220px;
  height: 220px;
  padding-top: 20px;
  background-color: rgba(128, 185, 248, 0.8);
}
.c3 {
  width: 180px;
  height: 180px;
  background-color: rgba(173, 209, 248, 0.5);
  border: 1px solid #eee;
  box-shadow: 0px 0px 10px #3fc;
  color: white;
  text-align: center;
  font-size: 14px;
  padding-top: 31px;
}
</style>
